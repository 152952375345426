// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`MyDetails.vue render when created  1`] = `
<div
  class="row"
>
  <div
    class="mb-4"
    col="12"
  >
    <div
      class="md-profile"
    >
      <div
        class="profile-picture__section"
      >
        <div
          class="left-content"
        >
          <h5
            class="profile-heading"
          >
            Profile picture
          </h5>
           
          <span
            class="profile-sub__heading"
          >
            Profile picture will only visible to you.
          </span>
        </div>
         
        <div
          class="right-content"
        >
          <div
            class="edit-img"
          >
            <div>
              <span
                class="upload-picture"
              >
                Upload Image
              </span>
            </div>
          </div>
           
          <div
            class="md-profile__avatar ml-4"
          >
            <img
              alt="avatar"
              class="rounded-circle"
              height="72"
              src=""
              width="72"
            />
          </div>
        </div>
      </div>
       
      <div
        class="md-profile--edit sc-content__edit--wrapper"
      >
        <b-modal
          centered=""
          header-class="modal-header--center modal-header--bb"
          hide-footer=""
          id="profile-pic-modal"
          modal-class="lxp-modal"
          no-close-on-backdrop="true"
          size="md"
        >
           
          <div
            class="profile-profile__div"
          >
            <b-row
              class="ep-div__sec"
            >
              <div
                class="w-100"
              >
                <img
                  alt="avatar"
                  class="rounded-circle"
                  height="215"
                  id="profile-pic"
                  src=""
                  width="215"
                />
              </div>
            </b-row>
             
            <div
              class="pp-footer__icons"
            >
              <div
                class="pp-footer__icons--edit"
              >
                <img
                  alt="edit"
                  src="@/assets/images/settings/icons/edit.svg"
                />
                 
                <span
                  class="d-block"
                >
                  Edit
                </span>
              </div>
               
              <div
                class="pp-footer__icons--upload"
              >
                <input
                  accept=".png,.jpg,.jpeg"
                  class="invisible"
                  id="profile"
                  type="file"
                />
                 
                <label
                  for="profile"
                >
                  <img
                    alt="camera"
                    height="21"
                    src="@/assets/images/settings/icons/camera.svg"
                    width="21"
                  />
                   
                  <span
                    class="d-block"
                  >
                    Upload
                  </span>
                </label>
              </div>
               
              <div
                class="pp-footer__icons--delete"
              >
                <img
                  alt="bin"
                  src="@/assets/images/settings/icons/bin.svg"
                />
                 
                <span
                  class="d-block"
                >
                  Delete
                </span>
              </div>
            </div>
          </div>
        </b-modal>
         
        <b-modal
          centered=""
          header-class="modal-header--center modal-header--bb"
          id="edit-pic-modal"
          modal-class="lxp-modal"
          no-close-on-backdrop="true"
          size="md"
        >
           
          <!---->
           
          <div
            class="edit-profile__div"
          >
            <div
              class="w-100"
            >
              <img
                alt="image"
                id="new-profile-pic"
                style="display: none;"
                width="280"
              />
            </div>
             
            <div
              class="edit-pic__actions"
            >
              <div
                class="ep-action__zoom"
              >
                <div
                  class="ep-action__zoom--heading"
                >
                  <label>
                    Zoom
                  </label>
                   
                  <label>
                    0.5
                  </label>
                </div>
                 
                <inputrange
                  id="zoom"
                  max="5"
                  min="0.1"
                  step="0.1"
                  value="0.5"
                />
              </div>
               
              <div
                class="ep-action__rotate"
              >
                <div
                  class="ep-action__zoom--heading"
                >
                  <label>
                    Rotate
                  </label>
                   
                  <label>
                    1
                  </label>
                </div>
                 
                <inputrange
                  id="rotate"
                  max="360"
                  min="0"
                  step="45"
                  value="1"
                />
              </div>
               
              <img
                alt="reset"
                class="c-pointer"
                src="@/assets/images/settings/icons/reset.svg"
                title="Reset"
              />
            </div>
          </div>
        </b-modal>
         
        <b-modal
          centered=""
          id="delete-profile-modal"
          modal-class="lxp-modal"
          size="md"
        >
           
          <div>
            <p>
              Are you sure you want to delete the profile photo?
            </p>
          </div>
        </b-modal>
      </div>
       
      <input
        accept=".png,.jpg,.jpeg"
        class="invisible"
        id="profile-pic"
        type="file"
      />
    </div>
  </div>
   
  <div
    class="mb-4"
    col="12"
  >
    <div
      class="md-general"
    >
      <div
        class="settings-card"
      >
        <div
          class="settings-card__heading"
        >
          <h5>
            General information
          </h5>
           
          <div
            class="sc-heading__actions"
          >
            <span
              class="sc-heading__actions--edit"
            >
              Edit
            </span>
          </div>
        </div>
         
        <div
          class="sc-content"
        >
          <div
            class="sc-content__content"
          >
            <div
              class="md-general__card-content"
            >
              <b-row>
                <b-col
                  class="mb-3"
                  cols="6"
                >
                  <label>
                    Email
                  </label>
                   
                  <p>
                    a@b.c
                  </p>
                </b-col>
                 
                <b-col
                  class="mb-3"
                  cols="6"
                >
                  <label>
                    Public name
                  </label>
                   
                  <p>
                    
              hello_w
            
                  </p>
                </b-col>
                 
                <b-col
                  class="mb-3"
                  cols="6"
                >
                  <label>
                    First Name
                  </label>
                   
                  <p>
                    World
                  </p>
                </b-col>
                 
                <b-col
                  class="mb-3"
                  cols="6"
                >
                  <label>
                    Last Name
                  </label>
                   
                  <p>
                    Hello
                  </p>
                </b-col>
                 
                <b-col
                  class="mb-3"
                  cols="6"
                >
                  <label>
                    Date of birth
                  </label>
                   
                  <p>
                    
                  </p>
                </b-col>
                 
                <b-col
                  class="mb-3"
                  cols="6"
                >
                  <label>
                    Gender
                  </label>
                   
                  <p>
                    
              
            
                  </p>
                </b-col>
                 
                <b-col
                  class="mb-3"
                  cols="6"
                >
                  <label>
                    Country
                  </label>
                   
                  <p>
                    -
                  </p>
                </b-col>
                 
                <b-col
                  class="mb-3"
                  cols="6"
                >
                  <label>
                    City/State
                  </label>
                   
                  <p>
                    -
                  </p>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   
  <div
    class="mb-4"
    col="12"
  >
    <div
      class="md-professional-info"
    >
      <div
        class="settings-card"
      >
        <div
          class="settings-card__heading"
        >
          <h5>
            Professional information
          </h5>
           
          <div
            class="sc-heading__actions"
          >
            <span
              class="sc-heading__actions--edit"
            >
              Edit
            </span>
          </div>
        </div>
         
        <div
          class="sc-content"
        >
          <div
            class="sc-content__content"
          >
            <b-row>
              <b-col
                class="mb-3"
                cols="6"
              >
                <label>
                  Affiliation
                </label>
                 
                <p>
                  
            -
          
                </p>
              </b-col>
               
              <b-col
                class="mb-3"
                cols="6"
              >
                <label>
                  Professional area
                </label>
                 
                <p>
                  
            -
          
                </p>
              </b-col>
               
              <b-col
                class="mb-3"
                cols="6"
              >
                <label>
                  Level of education
                </label>
                 
                <p>
                  
            -
          
                </p>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
   
  <div
    class="mb-4"
    col="12"
  >
    <div
      class="md-learning-interest"
    >
      <div
        class="settings-card"
      >
        <div
          class="settings-card__heading"
        >
          <h5>
            
        Learning Interests
        
            <img
              alt="Information"
              height="20"
              placement="bottom"
              src=""
              title="Enhance your learning experience! Tell us your learning interests, and we'll tailor courses recommendations based on what matters most to you."
              width="20"
            />
          </h5>
           
          <div
            class="sc-heading__actions"
          >
            <span
              class="sc-heading__actions--edit"
            >
              Edit
            </span>
          </div>
        </div>
         
        <div
          class="sc-content"
        >
          <div
            class="sc-content__content"
          >
            <div>
              <ul
                class="md-list"
              >
                <li>
                  <label>
                    Tell us about your learning goals
                  </label>
                   
                  <p>
                    -
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
